import loadable from '@loadable/component'
import React from 'react'
import { Link } from 'gatsby'
import config from '../../config'
import Helmet from 'react-helmet'
import PostCard from '../components/PostCard'
import Layout from '../components/Layout'
import SE0 from '../components/SEO'

const ContactCall = loadable(() => import('../components/ContactCall'))

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/blog/${props.url}`} className='button is-rounded'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='button is-rounded'>
        {props.text}
      </span>
    )
  }
}

const BlogPage = (props) => {
  const { pageContext: { first, group, index, last } } = props
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
  const nextUrl = (index + 1).toString() + '/'

  const websiteSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.siteUrl,
    name: config.siteTitle,
    alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
  }

  return (
    <Layout>
    <SE0
      title="Blog 🌱 Art Open Sp. z o.o. | Advertising agency "
      meta_title="Blog 🌱 Art Open Sp. z o.o. | Advertising agency "
      meta_desc="Advertising agency | Art Open 🌳 covid-19, ecology, gadgets, branding, websites and online shops, e-commerce, graphic design, VR and 3D, calendars."
      keywords="advertising agency, branding, websites, online shops, applications, ecology, e-commerce, positioning, seo, graphic design, book calendars, gadgets, prints, advertising, photography, filming, covid-19, surgical masks, masks with logo, exhibition, packaging, vr, 3D"
      slug="/blog/"
      cover="/img/blog.svg"
      siteTitleAlt={config.siteTitleAlt}
      userName={config.userName}
      siteTitle={config.siteTitle}
      siteUrl={config.siteUrl}
      siteFBAppID={config.siteFBAppID}
      userTwitter={config.userTwitter}
      pathPrefix={config.pathPrefix}
    />
      <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>
        {/* Schema.org tags */}
        <script type='application/ld+json'>
          {JSON.stringify(websiteSchemaOrgJSONLD)}
        </script>
        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://artopen.co/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Blog",
          }]
        })}
        </script>
      </Helmet>
      <section className='hero is-primary is-bold' id="blog-head" style={{minHeight:'70%',backgroundImage:'url(/img/blog.svg)',backgroundRepeat:'no-repeat',backgroundPosition:'center right',backgroundAttachment: `fixed`}}>
        <div className='hero-body'>
        <div className='container section'>
          <div className='columns section'>
            <div>
              <h1 className='title' style={{fontSize:'50px',marginTop:'50px'}}>
                Blog
              </h1>
              <h2 className='subtitle'>
              We invite you to read more.
              </h2>
            </div>
          </div>
        </div>
        </div>
      </section>
      <nav className="breadcrumbs-nav section">
      <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>Blog</b></span>
      </nav>
      <div style={{textAlign:'center',marginTop:'-20px',marginLeft:'10px',marginRight:'10px'}}>
      <h2 className='title'> Welcome to the studio blog of <b style={{color:'#23C495'}}>Art Open</b>.</h2>
      <h3 className='subtitle'> We write articles here on news, events and innovation.</h3>

      </div>

      <section className='section' style={{marginLeft:'auto',marginRight:'auto'}}>
        <PostCard posts={group} />
        <section className='section'>
          <div className='buttons is-centered'>
            <PaginationLink test={first} url={previousUrl} text='<' />
            <PaginationLink test={last} url={nextUrl} text='>' />
          </div>
        </section>
        <br />
        <div className='bottomUi' style={{textAlign:'center',marginBottom:'30px'}}>
        <Link className='button-green' style={{fontSize:'15px'}} to='/offer/'> Discover our offer&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{fontSize:'15px'}} to='/portfolio/'> See portfolio&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{fontSize:'15px'}} to='/about-us/'> Get to know us better&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        </div>
      </section>
      <ContactCall/>
    </Layout>
  )
}

export default BlogPage
